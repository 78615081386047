







































































































































































































































































































































































































































































































































































































































































































.home {
  .section-1 {
    overflow: hidden;
    width: 100%;
    min-height: calc(85vh - 320px);
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url('~@/assets/images/background/1.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 160px 0;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    color: #fff;
    .container-content {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      h1 {
        text-align: center;
        font-size: clamp(1.5rem, 3vw, 2rem);
        text-transform: capitalize;
        font-weight: 600;
      }
      span {
        padding-top: 10px;
        font-weight: 500;
        font-style: italic;
        text-align: center;
        font-size: clamp(1.1rem, 3vw, 1.7rem);
        text-transform: capitalize;
      }
      .register {
        margin-top: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        input {
          font-feature-settings: 'tnum';
          background-color: #fff;
          background-image: none;
          border: 1px solid #d9d9d9;
          box-sizing: border-box;
          color: rgba(0, 0, 0, 0.85);
          display: inline-block;
          font-variant: tabular-nums;
          line-height: 1.5715;
          list-style: none;
          margin: 0;
          min-width: 0;
          position: relative;
          transition: all 0.3s;
          width: 100%;
          border-radius: 50px;
          font-size: 16px;
          line-height: 28px;
          padding: 25px 200px 25px 30px;
          height: 70px;
          font-weight: 700;
        }
        button {
          width: 190px;
          height: 60px;
          position: absolute;
          line-height: 1.5em;
          padding: 17px 40px;
          border-radius: 50px;
          right: 5px;
          font-weight: 700;
          font-size: clamp(0.9rem, 3vw, 1.1rem);
          a {
            color: #fff;
          }
        }
        @media (min-width: 576px) {
          min-width: 525px;
        }
        @media (min-width: 768px) {
          min-width: 576px;
        }
        @media (min-width: 991px) {
          min-width: 700px;
        }
        @media (max-width: 425px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          input {
            height: 50px;
            padding: 5px 15px;
            width: 300px;
          }
          button {
            margin-top: 15px;
            position: relative;
            border: 5px solid #fff;
            height: 55px;
            padding: 0;
            min-width: 200px;
            width: 300px;
            right: 0;
          }
        }
      }
      .register-demo {
        margin-top: 20px;
        text-align: center;
        button {
          margin: auto;
          color: #fff;
          font-size: clamp(0.9rem, 3vw, 1.3rem);
          background: transparent;
          box-shadow: 0;
          border: 0;
          position: relative;
          &:after {
            content: '';
            width: 100%;
            height: 2px;
            background: #fff;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -5px;
          }
          &:hover {
            font-size: clamp(1rem, 3vw, 1.4rem);
          }
          a {
            color: #fff;
            text-decoration: none;
          }
        }
      }
    }
    @media (max-width: 1440px) {
      padding: 150px 0 80px;
      .container-content {
        h1 {
          font-size: clamp(1.3rem, 3vw, 1.6rem);
          margin-bottom: 0;
        }
        span {
          font-size: clamp(0.9rem, 3vw, 1.3rem);
        }
        .register {
          margin-top: 20px;
        }
      }
    }
  }
  .section-2 {
    z-index: 9;
    padding-top: 40px;
    padding-bottom: 40px;
    .box-list-trade {
      border-radius: 16px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
      width: 100%;

      .tabs-list-header {
        align-self: stretch;
        display: flex;
        flex: auto;
        overflow: hidden;
        position: relative;
        transform: translate(0);
        white-space: nowrap;
        justify-content: space-between;
        padding: 10px 10px 0;
        .tab-item {
          flex: 1;
          height: 100%;
          min-height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #777;
          font-weight: 600;
          cursor: pointer;
          margin: 0px 10px;
          &:active,
          &:hover,
          &.active {
            color: #009750;
            border-bottom: 2px Solid #009750;
          }
        }
        @media (max-width: 575px) {
          display: none;
        }
      }
      .tabs-list-body {
        background: #fff;
        min-height: 250px;
        table {
          margin-bottom: 0;
        }
      }
      .tabs-list-warning {
        background-color: #f6f7f8;
        border-top: 1px solid #f0f0f0;
        padding: 16px 24px;
        text-align: center;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
    .box-why {
      .mini-title {
        font-size: 1.125rem;
        font-weight: 600;
        text-transform: capitalize;
        text-align: justify;
        line-height: 120%;
      }
      h2.title {
        font-size: clamp(1.2rem, 3vw, 1.8rem);
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 15px;
      }
      .detail {
        font-size: 1.125rem;
        margin-bottom: 0;
        font-weight: 400;
        text-align: justify;
      }
      @media (min-width: 1600px) {
        padding-left: 25px;
      }
      @media screen and (min-width: 1200px) and (max-width: 1599px) {
        padding-left: 80px;
      }
      @media screen and (max-width: 991px) {
        max-width: 80%;
        margin: 40px auto 10px;
      }
    }
    @media (max-width: 1440px) {
      .box-why {
        h2.title {
          font-size: clamp(1.2rem, 3vw, 1.6rem);
        }
        .mini-title {
          font-size: 1rem;
          text-align: left;
        }
      }
      padding: 25px 0;
    }
    @media (max-width: 576px) {
      .box-why {
        h2.title {
          text-align: center;
        }
        .mini-title {
          text-align: center;
          font-size: 1rem;
        }
        .detail {
          text-align: center;
          font-size: 0.8rem;
        }
      }
    }
  }
  .section-3 {
    padding: 50px 0;
    .banner-box {
      @media (min-width: 768px) {
        min-height: 468px;
        padding: 0 64px;
      }
      min-height: 375px;
      padding: 20px;
      background: #000;
      color: #fff;
      align-items: center;
      border-radius: 16px;
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
      display: flex;
      overflow: hidden;
      padding: 16px;
      position: relative;
      box-sizing: border-box;
      .bg-img {
        img {
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          height: 100%;
          max-width: 860px;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 100;
          right: 0;
        }
      }
      .bg-color {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 200;
        background: linear-gradient(95.96deg, rgb(0, 0, 0) 40%, rgba(0, 0, 0, 0) 60%);
      }
      .text {
        width: 100%;
        max-width: 425px;
        position: relative;
        z-index: 300;
        h2.title {
          font-size: clamp(1.875rem, 3vw, 2.725rem);
          font-weight: 400;
          line-height: 110%;
          margin-bottom: 24px;
          text-align: initial;
        }
        .detail {
          font-size: 1.125rem;
          text-align: initial;
          margin-bottom: 0;
        }
      }
      @media (max-width: 575px) {
        .bg-color {
          background: linear-gradient(36deg, black 40%, rgba(0, 0, 0, 0) 80%);
        }
      }
    }
    @media (max-width: 1440px) {
      padding: 10px 0 40px;
      .banner-box {
        min-height: 285px;
        max-width: 992px;
        margin: auto;
        .text {
          h2.title {
            font-size: clamp(1.2rem, 3vw, 1.6rem);
          }
          .mini-title {
            font-size: 1rem;
            text-align: left;
          }
        }
      }
    }
    @media (max-width: 576px) {
      .banner-box {
        .text {
          h2.title {
            text-align: center;
            font-size: clamp(1.7rem, 3vw, 2.525rem);
          }
          .detail {
            text-align: center;
            font-size: 0.9rem;
          }
          .button-section {
            text-align: center !important;
            margin: auto;
            justify-content: center !important;
          }
        }
      }
    }
  }
  .section-4 {
    padding: 0 0 50px;
    .mini-title,
    h2.title {
      text-align: center;
      max-width: 840px;
      width: 80%;
      margin: {
        left: auto;
        right: auto;
      }
      text-transform: capitalize;
    }
    h2.title {
      font-size: clamp(1.2rem, 3vw, 1.8rem);
      font-weight: 600;
      line-height: 120%;
      margin-bottom: 15px;
    }
    .mini-title {
      font-size: 1.125rem;
      font-weight: 600;
      text-transform: capitalize;
      text-align: justify;
      line-height: 120%;
      margin-bottom: 50px;
    }

    .features-small-item {
      display: block;
      background: #ffffff;
      box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      padding: 30px;
      text-align: center;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      position: relative;
      margin-bottom: 30px;
      text-decoration: none !important;
      min-height: 300px;
      .icon {
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin: auto;
        position: relative;
        margin-bottom: 30px;
        background: #dae3ef;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        svg,
        i {
          font-size: 18px;
          color: #009750;
        }
      }
      .features-title {
        color: #333;
        font-size: clamp(15px, 3vw, 20px);
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 15px;
        letter-spacing: 0.7px;
        position: relative;
        z-index: 2;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        color: #555;
        letter-spacing: 0.88px;
        line-height: 26px;
        position: relative;
        z-index: 2;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
      }
      &:hover {
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          background-color: #28a745;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
          opacity: 1;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
        }
        .features-title {
          color: #fff;
        }
        p {
          color: #fff;
        }
      }
      @media (max-width: 991px) {
        max-width: 525px;
        margin: {
          left: auto;
          right: auto;
        }
      }
    }
    @media (max-width: 1440px) {
      h2.title {
        font-size: clamp(1.2rem, 3vw, 1.6rem);
      }
      .mini-title {
        font-size: 1rem;
        text-align: center;
      }
      .features-small-item {
        min-height: 255px;
        padding: 15px;
      }
      padding: 0px;
    }
  }
  .section-5 {
    min-height: 215px;
    overflow: hidden;
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: #f2f4f6;
    background: url('~@/assets/images/background/3.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.9);
    box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.9);
    .mini-title,
    h2.title {
      text-align: center;
      max-width: 900px;
      width: 80%;
      margin: {
        left: auto;
        right: auto;
      }
      text-transform: capitalize;
    }
    h2.title {
      font-size: clamp(1.2rem, 3vw, 1.8rem);
      font-weight: 600;
      line-height: 120%;
      margin-bottom: 15px;
      color: #fff;
    }
    .mini-title {
      font-size: 1.125rem;
      font-weight: 500;
      text-transform: capitalize;
      text-align: justify;
      text-align-last: center;
      line-height: 120%;
      margin-bottom: 50px;
      color: #fff;
      font-style: italic;
    }

    .achievment-box {
      border-radius: 16px;
      box-sizing: border-box;
      position: relative;
      background-color: #fff;
      padding: 30px 30px 30px;
      height: 320px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin: 12px auto;
      max-width: 75%;
      min-width: 275px;
      &:hover {
        filter: drop-shadow(0 4px 16px rgba(0, 0, 0, 0.08));
      }
      .icon {
        width: 50px;
        height: 50px;
        margin: 10px auto 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      h2 {
        color: #009750;
        font-size: 21px;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 15px;
        text-align: center;
      }
      p {
        text-align: center;
        margin-top: 15px;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        line-height: 140%;
        color: #333;
      }
      a {
        text-align: center;
        margin-top: 15px;
        color: #333;
        border-bottom: 1px solid #009750;
        text-decoration: none !important;
        span {
          color: #009750;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          text-transform: capitalize;
          line-height: 140%;
        }
        svg,
        i {
          font-size: 1rem;
          color: #009750;
          margin: auto 5px;
        }
      }
      @media (max-width: 991px) {
        min-height: 300px;
        height: auto;
        max-width: 575px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (max-width: 1440px) {
      h2.title {
        font-size: clamp(1.2rem, 3vw, 1.6rem);
      }
      .mini-title {
        font-size: 1rem;
        text-align: center;
        margin-bottom: 15px;
      }
      .achievment-box {
        padding: 15px;
        height: auto;
        min-height: 270px;
      }
      padding: 25px 0;
    }
  }
  .section-6 {
    padding: 50px 0;
    .banner-box {
      @media (min-width: 768px) {
        min-height: 468px;
        padding: 0 64px;
      }
      min-height: 375px;
      padding: 20px;
      background: #000;
      color: #fff;
      align-items: center;
      border-radius: 16px;
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
      display: flex;
      overflow: hidden;
      padding: 16px;
      position: relative;
      box-sizing: border-box;
      .bg-img {
        img {
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          height: 100%;
          max-width: 860px;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 100;
          right: 0;
          object-fit: cover;
        }
      }
      .bg-color {
        background: linear-gradient(46deg, black 35%, rgba(0, 0, 0, 0) 48%);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 200;
      }
      .text {
        width: 100%;
        max-width: fit-content;
        position: relative;
        z-index: 300;
        h2.title {
          font-size: 2.725rem;
          font-weight: 400;
          line-height: 3.0875rem;
          margin-bottom: 24px;
          text-align: initial;
        }
        .detail {
          font-size: 1.125rem;
          text-align: initial;
          margin-bottom: 0;
        }
        .button-section {
          display: flex;
          flex-direction: column;
          .button {
            letter-spacing: 0.05rem;
            position: relative;
            color: #fff !important;
            min-width: 150px;
            overflow: hidden;
            transition: 0.3s ease-in-out;
            border-radius: 0.3rem;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 10px;
            max-width: 300px;
            &:hover,
            &:focus,
            &:focus-visible {
              box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
            }
            &.btn-register {
              background: #009750;
              padding: 15px 10px;
              min-height: 55px;
              border-radius: calc(55px * 1.5);
            }
          }
        }
      }
      @media (max-width: 991px) {
        .bg-color {
          background: linear-gradient(26deg, #000000 10%, rgba(0, 0, 0, 0) 60%);
        }
      }
    }
    @media (max-width: 1440px) {
      padding: 20px 0;
      .banner-box {
        min-height: 285px;
        max-width: 992px;
        margin: auto;
        .text {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          h2.title {
            font-size: clamp(1.2rem, 3vw, 1.6rem);
            margin-bottom: 5px;
          }
          .detail {
            font-size: 1rem;
            margin-bottom: 0 !important;
          }
          .button-section {
            .btn-register {
              margin: 5px 0 10px;
            }
          }
        }
      }
    }
  }
}
